<template>
  <div class="textfield">
    <div
      class="textfield__wrapper"
      :class="{
        error,
        disabled,
        [`i-${icon}`]: icon,
        labeled: label?.length,
        filled,
        focused: isFocused,
      }"
    >
      <fieldset aria-hidden="true">
        <legend>{{ label || placeholder }}</legend>
      </fieldset>
      <label
        v-if="label && !placeholder"
        ref="labelElement"
        :for="id"
      >
        {{ label }}
      </label>
      <input
        :id="id"
        ref="inputElement"
        v-mask="mask"
        :placeholder="placeholder"
        v-bind="{
          ...$attrs,
          ...autofillAttrs,
        }"
        :type="inputType"
        :disabled="disabled"
        :value="modelValue"
        @keypress="checkValue"
        @input="handleInput"
        @change="handleChange"
        @focus="handleFocus"
        @focusout="handleFocusOut"
        @blur="handleBlur"
      >
      <button
        v-if="clearable && (isString(modelValue) ? modelValue.length > 0 : modelValue)"
        class="textfield__clear"
        :class="`i-cancel-grey${disabled ? '-light' : ''}`"
        @click="clear"
      />
      <div
        v-if="appendClass"
        :class="appendClass"
        @click="handleAppendClick"
      >
        <slot name="append" />
      </div>
    </div>

    <div
      v-if="message"
      class="textfield__message"
      :class="{
        'i-check-green': success,
        'textfield__success': success,
        'textfield__error': error,
      }
      "
    >
      {{ message }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  defineComponent,
  ref
} from 'vue'
import { isNumber, isString } from '@/utils/check-runtime-types'
import { isNotEmptyString } from '@/utils/string'
import { getVMask } from '@/utils/input/mask-directive'

import type { InputHTMLAttributes, PropType } from 'vue'
import type { IconName } from '@/utils/icon-types'
import type { InputMask } from '@/utils/input/helpers'

defineComponent({ name: 'ATextfields', inheritAttrs: false })

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ''
  },
  type: {
    type: String as PropType<
      'email' | 'number' | 'password' | 'search' | 'tel' | 'text'
    >,
    default: 'text',
    validator: (type: string) =>
      ['email', 'number', 'password', 'search', 'tel', 'text'].includes(type)
  },
  name: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  clearable: Boolean,
  appendClass: {
    type: [String, Object, Array],
    default: ''
  },
  disabled: Boolean,
  message: {
    type: String,
    default: ''
  },
  success: Boolean,
  error: Boolean,
  placeholder: {
    type: String,
    default: null
  },
  icon: {
    type: String as PropType<IconName>,
    default: undefined
  },
  mask: {
    type: String as PropType<InputMask>,
    default: undefined
  },
  autocomplete: {
    type: String,
    default: 'on'
  }
})

const emit = defineEmits([
  'input',
  'update:modelValue',
  'change',
  'focus',
  'focusout',
  'blur',
  'click:append'
])

const vMask = getVMask()

const id = `textfield-${Math.random().toString(36)}`
const isFocused = ref(false)
const autofillAttrs = computed<Partial<InputHTMLAttributes>>(() => {
  if (isNotEmptyString(props.name)) {
    return {
      name: props.name,
      autocomplete: props.autocomplete || 'on'
    }
  }

  return {
    autocomplete: 'off',
    role: 'presentation'
  }
})

const showPassword = ref(false)
const inputType = computed(() =>
  props.type === 'password' && showPassword.value ? 'text' : props.type
)

const filled = computed(
  () =>
    (isString(props.modelValue) && isNotEmptyString(props.modelValue)) ||
    (isNumber(props.modelValue) && props.modelValue > 0)
)

const handleAppendClick = (): void => {
  emit('click:append')
}

const inputElement = ref<null | HTMLInputElement>(null)
const labelElement = ref<null | HTMLLabelElement>(null)

const focus = (): void => inputElement.value?.focus()
const blur = (): void => inputElement.value?.blur()

const checkValue = (event: KeyboardEvent): void => {
  if (props.type === 'number' && !/[0-9]/g.test(event.key)) {
    event.preventDefault()
  }
}

const getValue = (event: Event): string => {
  const target = event.target as HTMLInputElement
  let value = target.value

  if (!isString(value)) { return '' }

  if (props.type === 'number') { value = value.replace(/\D/g, '') }
  return value
}

const handleFocus = (event: FocusEvent): void => {
  isFocused.value = true
  emit('focus', event)
}

const handleFocusOut = (event: FocusEvent): void => {
  isFocused.value = false
  emit('focusout', event)
}

const handleBlur = (event: FocusEvent): void => {
  emit('blur', event)
}

const handleInput = (event: Event): void => {
  emit('input', getValue(event))
  emit('update:modelValue', getValue(event))
}

const clear = (): void => {
  emit('update:modelValue', '')
}

const handleChange = (event: Event): void => {
  emit('change', getValue(event))
}

defineExpose({
  inputElement,
  labelElement,
  focus,
  blur
})
</script>

<style lang="postcss">
.textfield {
  --textfields-border-width: 1px;
  --textfields-border-color: var(--color-neutral-400);
  --textfields-transition-fn: cubic-bezier(0.25, 0.8, 0.5, 1);
  --textfields-height: var(--spacer-4xl);

  @mixin text-base;
}

.textfield__clear {
  min-width: 1.5rem;
  color: var(--color-neutral-400);
  opacity: 0;
  transition: opacity 0.3s var(--textfields-transition-fn);

  @media (hover: hover) and (--screen-lg) {
    &:hover {
      opacity: 0.5 !important;
      cursor: pointer;
    }
  }
}

.textfield__password {
  min-width: 1.5rem;
  color: var(--color-icon-middle);
  cursor: pointer;

  @media (hover: hover) and (--screen-lg) {
    &:hover {
      color: var(--color-neutral-600);
    }
  }
}

.textfield__wrapper {
  position: relative;
  display: flex;
  gap: var(--spacer-xs);
  align-items: center;
  height: var(--textfields-height);
  padding: 0 var(--spacer-2xs) 0 var(--spacer-xs);

  & > button {
    height: 1.5rem;
  }

  &::before {
    position: absolute;
    top: calc(50% - var(--spacer-2xs));
    right: var(--spacer-2xs);
  }

  &.labeled {
    margin-top: 5px;
  }

  & > fieldset {
    position: absolute;
    padding-left: calc(var(--spacer-2xs) - var(--textfields-border-width));
    border: var(--textfields-border-width) solid var(--textfields-border-color);
    border-radius: var(--border-radius-xs);
    pointer-events: none;
    inset: -7px 0 0;

    & > legend {
      width: 0;
      white-space: nowrap;
      visibility: hidden;
    }

    @mixin text-xs;
  }

  &.filled {
    --textfields-border-color: var(--color-neutral-500);

    &:not(.disabled) > [type="search"] ~ .textfield__clear {
      opacity: 1;
    }

    @media (hover: hover) and (--screen-lg) {
      &:hover {
        --textfields-border-color: var(--color-neutral-600);
      }
    }
  }

  &:focus-within {
    --textfields-border-width: var(--spacer-5xs);
    --textfields-border-color: var(--color-neutral-500);

    & > .textfield__clear {
      opacity: 1;
    }
  }

  &.filled.labeled,
  &.labeled:focus-within {
    & > fieldset > legend {
      width: auto;
      padding: 0 var(--spacer-4xs);
    }

    & > label {
      transform: translateY(-24px) scale(0.75);
    }
  }

  &.error:not(.disabled) {
    --textfields-border-width: var(--spacer-5xs);
    --textfields-border-color: var(--color-danger-dark);

    &.focused > label,
    &.filled > label {
      color: var(--color-danger-dark);
    }

    @media (hover: hover) and (--screen-lg) {
      &:hover {
        --textfields-border-color: var(--color-danger-dark);
      }
    }
  }

  &.disabled {
    --textfields-border-color: var(--color-neutral-300);

    & input {
      color: var(--color-text-middle);

      &::placeholder {
        color: var(--color-text-light);
      }
    }

    @media (hover: hover) and (--screen-lg) {
      &:hover {
        --textfields-border-color: var(--color-neutral-300);
      }
    }
  }

  @media (hover: hover) and (--screen-lg) {
    &:hover {
      --textfields-border-color: var(--color-neutral-500);

      &:not(.disabled) > .textfield__clear {
        opacity: 1;
      }
    }
  }
}

.textfield label {
  position: absolute;
  color: var(--color-text-light);
  white-space: nowrap;
  cursor: text;
  user-select: none;
  transition: transform 0.3s var(--textfields-transition-fn);
  transform-origin: center left;
}

.textfield input {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: var(--spacer-3xs);
  background-color: transparent;
  color: var(--color-text-dark);
  outline: none;
  appearance: textfield;

  &::-ms-reveal {
    display: none;
  }

  @mixin text-base;
}

input[type]::-webkit-search-cancel-button {
  display: none;
}

input[type]:focus::-webkit-search-cancel-button {
  opacity: 1;
  cursor: pointer;
  pointer-events: all;
}

input[type="search"] ~ .textfield__clear {
  opacity: 0;
}

.textfield__message {
  display: flex;
  grid: var(--spacer-4xs);
  overflow-x: hidden;
  min-height: var(--fs-base);
  margin-top: var(--spacer-4xs);
  margin-left: var(--spacer-xs);
  color: var(--color-text-middle);
  user-select: none;

  &::before {
    width: 1rem;
    height: 1rem;
  }

  @mixin text-xs;
}

.textfield__error {
  color: var(--color-danger-dark);
}

.textfield__success {
  gap: var(--spacer-4xs);
  color: var(--color-success-dark);
}

input[type="number"] {
  appearance: textfield;
}

input::placeholder {
  color: var(--color-text-light);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
}
</style>
