<template>
  <ul :class="rootClasses">
    <li
      v-for="n in count"
      :key="`dot-${n * Math.random()}`"
      :class="dotClasses(n)"
      @click="emitClick(n)"
    />
  </ul>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'ACarouselDot',
  props: {
    count: {
      type: Number,
      required: true
    },
    current: {
      type: Number,
      default: 0
    },
    variant: {
      type: String as PropType<'sm' | undefined>,
      default: undefined
    },
    color: {
      type: String as PropType<'light' | undefined>,
      default: undefined
    }
  },
  emits: ['update:current'],
  setup (props, { emit }) {
    const { current, count } = toRefs(props)
    const rootClasses = computed(() => {
      const list = ['a-carousel-dot']
      if (props.variant) { list.push(`a-carousel-dot--${props.variant}`) }
      if (props.color) { list.push(`a-carousel-dot--${props.color}`) }
      return list
    })

    const dotClasses = (n: number): string[] => {
      const list = []
      const idx = n - 1
      if (idx === current.value) { list.push('current') }
      if (count.value <= 5) { return list }

      const classes = {
        2: 'small',
        3: 'smaller',
        4: 'smallest'
      }

      for (const [key, value] of Object.entries(classes)) {
        if ([current.value + +key, current.value - +key].includes(idx)) { list.push(value) }
      }
      if (idx >= current.value + 5 || idx <= current.value - 5) { list.push('invisible') }

      return list
    }

    const emitClick = (n: number): void => {
      emit('update:current', n - 1)
    }

    return {
      rootClasses,
      dotClasses,
      emitClick
    }
  }
})
</script>

<style lang="postcss">
.a-carousel-dot {
  --dot-color: var(--color-neutral-400);
  --dot-color-active: var(--color-neutral-900);
  --dot-size: var(--spacer-3xs);

  display: flex;
  gap: var(--spacer-4xs);
  align-items: center;
  justify-content: center;
  width: 100%;

  & > li {
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: var(--border-radius-xs);
    background-color: var(--dot-color);
    cursor: pointer;

    &.small {
      transform: scale(0.75);
    }

    &.smaller {
      transform: scale(0.5);
    }

    &.smallest {
      transform: scale(0.25);
    }
  }

  & li.current {
    background-color: var(--dot-color-active);
  }

  & li.invisible {
    display: none;
  }

  &--sm {
    --dot-size: calc(var(--spacer-sm) / 4);

    gap: var(--spacer-5xs);

    & > li {
      &.small {
        --dot-size: var(--spacer-4xs);
      }

      &.smaller {
        --dot-size: var(--spacer-5xs);
      }

      &.smallest {
        display: none;
      }
    }
  }

  &--light {
    --dot-color: rgb(255 255 255 / 50%);
    --dot-color-active: var(--color-white);
  }
}
</style>
