import { joinURL } from 'ufo'
import type { ProviderGetImage } from '@nuxt/image'

export const getImage: ProviderGetImage = (src, opts = {}) => {
  const runtimeConfig = useRuntimeConfig()
  const baseURL = runtimeConfig.public.cdnUrl

  if (opts.modifiers?.width) {
    src = src.replace('%w', opts.modifiers.width.toString())
  }

  if (opts.modifiers?.height) {
    src = src.replace('%h', opts.modifiers.height.toString())
  }

  return {
    url: joinURL(baseURL, src)
  }
}
