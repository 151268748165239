import { joinURL } from 'ufo'
import type { ProviderGetImage } from '@nuxt/image'

export const getImage: ProviderGetImage = (src) => {
  const runtimeConfig = useRuntimeConfig()
  const baseURL = runtimeConfig.app.cdnUrl

  return {
    url: joinURL(baseURL, src)
  }
}
